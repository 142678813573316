body {
  font-family: "Nunito", sans-serif;
  margin: 0px;
  background-color: #212426;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .logo {
    animation: App-logo-spin infinite 10s linear;
  }
}
